import React, { useState, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import financial from '../schema/financial.json';
import uifinancial from '../uischema/uifinancial.json';
import {
    materialCells,
    materialRenderers,
} from '@jsonforms/material-renderers';
import { Grid, Typography, Button } from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import PageContainer from "../components/PageContainer";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: 60,
            paddingLeft: '20px',
            paddingRight: '20px'
        },
        heading: {
            fontSize: '1.4rem'
        }
    })
);
// dummy data
const value = {
};
//ui renders
const renderers = [...materialRenderers];

const Financial = (props) => {
    const classes = useStyles();
    const [jsonformsInputData, setJsonformsInputData] = useState(value);
    const [jsonformsOutputData, setJsonformsOutputData] = useState(value);
    const [ele, setele] = useState('');
    const [more,catchMore] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (ele) {
            const nxtBt = ele.getElementsByTagName("button")[5].getElementsByTagName("span");
            const prvBt = ele.getElementsByTagName("button")[4].getElementsByTagName("span");
            nxtBt[0].innerText = "Previous Year";
            prvBt[0].innerText = "Next Year";
        }

    }, [ele])
    useEffect(() => {

        if (window.fin) {
            setJsonformsInputData(window.fin);
        }else{
            handleClickOpen()
        }
        const content = document.getElementsByClassName("financial")[0];
        if (content) {
            setele(content)
        }
    }, [props]);
    const formAction = (data, error) => {
        setJsonformsOutputData(data);
    }
    const common = () => {
        if (jsonformsOutputData) { 
            window.fin = jsonformsOutputData; 
        }
        catchMore(true);
    }
    const goingTo = () => {
        if (jsonformsOutputData) { window.fin = jsonformsOutputData; }
        props.goback(true)
    }
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
   
    return (<>{more?<PageContainer />
             :<div className={classes.root}>
             <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h1" align="center" gutterBottom className={classes.heading}>
                        Please enter your Financial Statement for the last four years
                    </Typography>
                </Grid>
                <Grid item xs={12} className="financial">
                    <JsonForms
                        schema={financial}
                        uischema={uifinancial}
                        data={jsonformsInputData}
                        renderers={renderers}
                        cells={materialCells}
                        onChange={({ errors, data }) => formAction(data, errors)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" className="action "  onClick={() => goingTo()}>Back</Button>
                    <Button variant="contained" className="action "  onClick={() => common()}>Skip</Button>
                    <Button variant="contained" className="action "  onClick={() => common()}>Submit</Button>
                </Grid>
            </Grid>
        </div>
}
<Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <Typography variant="h6" color="textPrimary" >
                   
          Do you have a financial statement and would you like to fill out that information here?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">
            Yes
          </Button>
          <Button   onClick={() =>{ handleClose();common()}} color="primary">
            No
          </Button>
          
        </DialogActions>
      </Dialog>
   
</>);
}

export default Financial;