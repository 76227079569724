import flashcards from '../Player/stories/flashcards';
import financial_decisions from '../Player/stories/financial_decisions';
import financial_decisions_euro from '../Player/stories/financial_decisions_euro';
import finding_patterns from '../Player/stories/finding_patterns';
import fire_building from "../Player/stories/fire_building";
import coins_personal from "../Player/stories/coins_personal";
import self_image from "../Player/stories/self_image";
import demographic_info from "../Player/stories/demographic_info";
import trans_start from "../Player/stories/trans_start";
import trans_start_custom from "../Player/stories/trans_start_custom";
import trans_end from "../Player/stories/trans_end";
import sand_truck from "../Player/stories/sand_truck";
import balloon_game from "../Player/stories/balloon_game";
import trans_start_welcome from '../Player/stories/trans_start_welcome';

export default {
    flashcards ,
    financial_decisions,
    financial_decisions_euro,
    finding_patterns,
    fire_building,
    coins_personal,
    self_image,
    demographic_info,
    trans_start,
    trans_start_custom,
    trans_end,
    sand_truck,
    balloon_game,
    trans_start_welcome
}