'use strict';

import React, { useState, useContext } from 'react';

import { ThankYouButton, ThankYouContainer, ThankYou } from './ModuleFinishStyles';
import { CenterContent } from '../../assets/styles/BaseStyle';
import { GlobalBaseStyle } from '../../assets/styles/BaseStyle';
import { LocaleContext } from '../../context/LocalContext';
import { extract_text } from '../../helpers';
import _get from 'lodash.get';

const ModuleFinish = ({ handleClick, module, title, observation }) => {
  const translation = useContext(LocaleContext);
  const [clicked, setClickState] = useState(false);

  const handleButtonClick = () => {
    // if (!clicked) {
    handleClick(module);
    setClickState(true);
    // }
  };

  return <>
    <GlobalBaseStyle />
    <CenterContent center={true}>
      <ThankYouContainer className='lefl-module-title'>
        <ThankYouButton className='lefl-module-title' onClick={handleButtonClick} />
        <ThankYou className='lefl-module-title'>{extract_text(title) || _get(translation, `${observation}.title`)}</ThankYou>
      </ThankYouContainer>
    </CenterContent>
  </>
};

export default ModuleFinish;
