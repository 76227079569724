'use strict'

import React, { useState, useEffect, useContext } from 'react'
import Slider from 'rc-slider'
import PropTypes from 'prop-types'

import BaseSlider from '../../components/BaseSlider/BaseSlider'
import {GlobalBaseStyle, CenterContent, Question, Hint, Instruction} from '../../assets/styles/BaseStyle';
import {RcSliderStyle} from '../../assets/styles/RCSliderStyle';
import {SliderBaseContainer, SliderBaseLabel, BaseOption, SliderBase, SliderBaseBackground} from '../../components/BaseSlider/BaseSliderStyle'
import SliderHandle from '../../components/BaseSlider/SliderHandle'
import {ImageContainer, LeftImageContainerBorder, RightImageContainerBorder, SliderImage, ImageWrapper} from './ImageStyles'
import sliderBackground from '../../assets/images/slider-bg.svg'
import {extract_text} from '../../helpers';
import {LocaleContext} from '../../context/LocalContext';
import {loadImage} from '../../helpers/image_loader';
import _get from 'lodash.get';
import {parse_image} from '../../helpers';

const ImageSlider = ({
   observation, value, defaultValue,
   min, max, handPointer, onAfterChange, onChange,
   option1, option2, roundedImages, title, sub_title,
   center, instruction, journey_state }) => {

  const translation = useContext(LocaleContext);
  const opt1Label = React.createRef();
  const opt2Label = React.createRef();

  const calcScale = (scale) => {
    const scaleRange = {min: 0.4, max: 1};
    return scaleRange.min + (scaleRange.max - scaleRange.min) * scale;
  };

  const getImageScale = (value) => {
    const scale = (value - min) / (max - min);
    const right = calcScale(scale);
    const left = calcScale(1 - scale);

    return { left, right }
  };

  const [sliderValue, setValue] = useState(value || defaultValue);
  const [imagesRatio, setImageRatio] = useState(getImageScale(value || defaultValue));

  useEffect(() => {
    setValue(value || defaultValue);
    setImageRatio(getImageScale(value || defaultValue));
  }, [observation]);

  const handleSlide = (value) => {
    const idx = Math.ceil((value / 50));
    let opt_label = _get(`option${idx}`, 'label', ' ') || value;

    if (value === defaultValue) opt_label = " ";

    if (typeof onChange === 'function') onChange(value, opt_label);

    setValue(value);
    setImageRatio(getImageScale(value));
	};

	const renderImageOptions = () => {
		return (
			<ImageContainer circular={roundedImages}>
				<ImageWrapper circular={roundedImages} transform={imagesRatio.left || 0}>
					<LeftImageContainerBorder circular={roundedImages}>
						<SliderImage circular={roundedImages} image={loadImage(parse_image(option1.image, journey_state))} />
					</LeftImageContainerBorder>
				</ImageWrapper>
				<ImageWrapper circular={roundedImages} transform={imagesRatio.right || 0}>
					<RightImageContainerBorder circular={roundedImages}>
						<SliderImage circular={roundedImages} image={loadImage(parse_image(option2.image, journey_state))} />
					</RightImageContainerBorder>
				</ImageWrapper>
			</ImageContainer>
		)
	};

  const _props = {
    onAfterChange,
    onChange: handleSlide,
    min,
    max,
    value: sliderValue,
    handle: SliderHandle,
    key: 'slider'
  };

  return (
    <>
      <GlobalBaseStyle />
      <RcSliderStyle />
      <CenterContent center={center}>
        {
          !handPointer && <>
            <Question alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question`)}</Question>
            <Instruction
                visible={instruction}
                alignment={_get(instruction, 'alignment', 'left')}
            >
              {extract_text(instruction) || _get(translation, `${observation}.instruction`, '')}
            </Instruction>
            <Hint
                visible={sub_title} render_as={_get(sub_title, 'as', 'hint')}
                alignment={ _get(sub_title, 'alignment', 'left')}
            >
              {extract_text(sub_title) || _get(translation, `${observation}.hint`, _get(translation, `${observation}.subquestion`))}
            </Hint>
          </>
        }
        <SliderBaseContainer pointer={handPointer}>
          {renderImageOptions()}
          <SliderBaseLabel>
            <BaseOption ref={opt1Label} align={'left'}>
              <SliderBaseLabel>{option1.label || _get(translation, `${observation}.option1`)}</SliderBaseLabel>
            </BaseOption>
            <BaseOption ref={opt2Label} align={'right'}>
              <SliderBaseLabel>{option2.label || _get(translation, `${observation}.option2`)}</SliderBaseLabel>
            </BaseOption>
          </SliderBaseLabel>
          <SliderBase>
            <SliderBaseBackground src={sliderBackground} />
            <Slider {..._props} />
          </SliderBase>
        </SliderBaseContainer>
      </CenterContent>
    </>
  )
};

ImageSlider.defaultProps = {
  title: '',
  sub_title: ''
};

ImageSlider.propTypes = {
  onChange: PropTypes.func
};

export default BaseSlider(ImageSlider)