import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const initLocale = "en";

function loadMessages(locale) {
    switch (locale) {
        case "es":
            return import("../pages/locale/es.json");
        case "en":
            return import("../pages/locale/en.json");
        case "th":
            return import("../pages/locale/th.json");
        case "fr":
            return import("../pages/locale/en.json");   
        case "ny":
            return import("../pages/locale/ny.json");
        case "lg":
            return import("../pages/locale/lg.json");
        case "rw":
            return import("../pages/locale/rw.json");
        case "es-es":
            return import("../pages/locale/es-es.json");
        case "el":
            return import("../pages/locale/el.json")
        case "ur":
            return import("../pages/locale/ur.json")
        default:
            return import("../pages/locale/en.json");
    }
}
const useLanguage = () => {
    const { client } = useSelector(State => State.config);
    const [locale, setLocale] = useState(client?.languageCode || initLocale);
    const [messages, setMessages] = useState(null);

    useEffect(() => loadMessages(locale).then(setMessages), [locale]);

    return { messages, locale, setLocale }
}

export default useLanguage
