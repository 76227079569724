import React, { useContext, useEffect, useState } from 'react';
import { loadImage } from '../../helpers/image_loader';
import { Body, Text, Boxs, Box, TrukImg } from './ScoreBoardStyle';
import _get from 'lodash.get';
import { LocaleContext } from '../../context/LocalContext';
import { GlobalBaseStyle } from '../../assets/styles/BaseStyle';

const ScoreBoard = ({ containerName, status, image, observation, onChangeHandler, previousValues = {}, preObs }) => {
    const translation = useContext(LocaleContext);
    const [Status, setStatus] = useState();
    const [scoreBoard, SetScoreBoard] = useState([0, 0]);
    const [subTitle, setSubTitle] = useState('');
    const [score, setScore] = useState(0);

    useEffect(() => {
        if (previousValues[preObs]) {
            const { totalScore, score } = previousValues[preObs];
            setScore(totalScore);
            if (totalScore <= score) {
                SetScoreBoard(() => {
                    const board = totalScore.toString().split('');
                    if (board.length === 1) return [0, +board[0]];
                    else return [...board];
                });
                setStatus('success')
            } else {
                SetScoreBoard([0, 0]);
                setStatus('dangar');
            }
        }
    }, [])

    useEffect(() => {
        if (Status) {
            console.log('Status', translation[observation][Status]?.sub_title);
            setSubTitle(eval(translation[observation][Status]?.sub_title));
            onChangeHandler({ value: Status });
        }

    }, [Status])

    return (
        <>
            <GlobalBaseStyle />
            <Body status={Status}>
                <Text className='lefl-module-title'>{_get(translation, `${observation}.points`, '')}</Text>
                <Boxs className='ltrDir'>
                    <Box status={Status}>{scoreBoard[0]}</Box>
                    <Box status={Status}>{scoreBoard[1]}</Box>
                </Boxs>
            </Body>
            {image && Status === 'dangar' && <TrukImg containerName={containerName}>
                <img src={loadImage(image)} alt=""/>
            </TrukImg>}
            <div className='lefl-module-title directionRtl' style={{ textAlign: 'center', fontSize: '25px',marginTop: '10px' }}>
                <b>{_get(translation, `${observation}.${Status}.title`, '')}</b>
                <p className='lefl-module-title'>{subTitle}</p>
            </div>
        </>
    );
};

export default ScoreBoard;