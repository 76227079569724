// eslint-disable-next-line import/no-anonymous-default-export
export default {
    screens: [
        {
            observation: 'journey_start',
            module: 'trans_start_welcome',
            type: 'start_trans',
            help: false,
            meta: {
                hiddenHeader: true,
                v2: true
            },
            auto_advance: true,
            timer: {
                time: 30000,
                extend: false,
                pause: false,
                visible: false
            },
            screen_submit: true,
            mobileLogoVisible: true,
        },
    ],
    screen_submit: true

}