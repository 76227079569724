/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import {GlobalBaseStyle, CenterContent, Question, Hint, Warning, Instruction} from '../../assets/styles/BaseStyle';
import {Group} from './GroupCheckboxButtonStyles'
import CheckBoxButton from '../../components/CheckboxButton/CheckboxButton'
import {extract_text} from '../../helpers';
import {LocaleContext} from '../../context/LocalContext';
import _get from 'lodash.get';
import shuffleArray from '../../lib/shuffleArray';
const GroupCheckboxButton = ({
  observation, title, options, center, handPointer,
  value, defaultValue, onChangeHandler, hide_hands,
  handTarget, clearing_options, type, sub_title, warning,
  instruction,selectionType,hideLables }) => {
  const translation = useContext(LocaleContext);
  const [selections, updateSelections] = useState(_get(value, 'value', defaultValue) || []);
  const [is_upated, setUpdatedStatus] = useState(false);
  const [sequence, setSequence] = useState(_get(value, 'sequence', []));
   useEffect(()=>{
     if(options){
      options = shuffleArray(options);
     }
   },[])
	useEffect(() => {
    updateSelections(_get(value, 'value', defaultValue) || []);
    setSequence(_get(value, 'sequence', []));
  }, [observation, value]);

  const handleSelect = (value,textSelected) => {
    if(selectionType === 'single'){
      updateSelections([value]);
      const newSequence = sequence;

      if (value !== newSequence[newSequence.length - 1]) newSequence.push(value);
  
      setSequence(newSequence);
  
      if (typeof onChangeHandler === 'function') {
        onChangeHandler({ value: value, labels: textSelected, sequence: newSequence });
      }
    }else{
    const in_clearing_option = (val) => clearing_options && clearing_options.values.includes(val);
    const clearing_option_rule = clearing_options && clearing_options.rule;

    let newSelections = selections;
    setUpdatedStatus(true);

    // Handles un-checking
    if (selections.includes(value)) {
      newSelections = selections.filter(selected => selected !== value);
    } else {
      // Handles checking
      newSelections = selections.filter(selected => !in_clearing_option(selected)).concat([value]);
    }

    const shouldClearSelections = in_clearing_option(value) && newSelections.includes(value);
    let _selections = newSelections;

    if (shouldClearSelections) {
      _selections = selections.filter(selected => clearing_option_rule === 'group' && in_clearing_option(selected)).concat([value]);
    }

    updateSelections(_selections);
    const labels = [];

    options.forEach((option, index) => {
      if(_selections.includes(option.value)) labels.push(index)
    });

    const newSelectionsLabels = labels.map((index) => _get(options[index], 'label', _get(translation, `${observation}.option${index + 1}`)));
  
    const newSequence = sequence;

    if (value !== newSequence[newSequence.length - 1]) newSequence.push(value);

    setSequence(newSequence);

    if (typeof onChangeHandler === 'function') {
      onChangeHandler(_selections && _selections.length ? { value: _selections, labels: newSelectionsLabels, sequence: newSequence } : null);
    }
  }
  };

  const generateOptions = () => {
    return options.map((item, index) => {
      const is_selected = selections.includes(item.value);

      return <CheckBoxButton
          key={index}
          identifier={index}
          onClick={() => handleSelect(item.value,item.label || _get(translation, `${observation}.option${index + 1}`))}
          pointer={handPointer && handTarget === item.value}
          hide_hands={hide_hands}
          selected={is_selected}
          image={item.image}
          label={_get(item, 'label' , _get(translation, `${observation}.option${index + 1}`))}
          type={type}
          selectionType={selectionType}
          hideLables={hideLables}
        />
    });
  };

  return <>
    <GlobalBaseStyle />
    <CenterContent center={center}>
      <div>
        {
          !handPointer && <>
            <Question alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question`)}</Question>
            <Instruction
              visible={instruction}
              alignment={_get(instruction, 'alignment', 'left')}
            >
              {extract_text(instruction) || _get(translation, `${observation}.instruction`, '')}
            </Instruction>
            <Hint
              visible={sub_title} render_as={_get(sub_title, 'as', 'hint')}
              alignment={ _get(sub_title, 'alignment', 'left')}
            >
              {extract_text(sub_title) || _get(translation, `${observation}.hint`, _get(translation, `${observation}.subtitle`))}
            </Hint>
            <Warning size={_get(warning, 'size', 'large')} visible={warning && is_upated && selections.length < 1}>{extract_text(warning) || _get(translation, `${observation}.warning`, '')}</Warning>
          </>
        }
      </div>
      <Group  type={type}>
        {generateOptions()}
      </Group>
    </CenterContent>
  </>
};

GroupCheckboxButton.defaultProps = {
	options: [],
  defaultValue: [],
	handPointer: false,
  title: '',
  sub_title: '',
  warning: '',
  instruction: ''
};

GroupCheckboxButton.propTypes = {
	value: PropTypes.object,
  handPointer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
	options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func
};

export default GroupCheckboxButton