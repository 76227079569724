import {React} from 'react';
import { Checkbox, DummyLabel, Span, Option, ImageContainer, OptionWrapper, Label } from './RadioButtonStyles';

const CheckBoxButton = ({ fontSize, identifier, selected, pointer, label, onClick, type, className, imgSrc }) => {

  return <Option key={identifier} id={identifier} pointer={pointer} onClick={onClick} type={type} className= {className?className:''}>
    <Checkbox type="radio" />
    <Span selected={selected} >
      <span />
    </Span>
    { imgSrc &&
    <img src={imgSrc} />
    }
    <Label className='rtl-text' fontSize={fontSize} htmlFor={identifier}
    >{label}</Label>
    <DummyLabel />
  </Option>
};

export default CheckBoxButton;
