'use strict';

import React, { useState, useContext } from 'react';

import { ThankYouButton, ThankYouContainer, ThankYou,ThankYouDesc } from './ServeyCompleteStyles';
import { CenterContent, GlobalBaseStyle } from '../../assets/styles/BaseStyle';
import { LocaleContext } from '../../context/LocalContext';
import { extract_text } from '../../helpers';
import { loadImage } from '../../helpers/image_loader';
import _get from 'lodash.get';

const ServerComplete = ({ handleClick, image, module, title, observation,desc,showUid,uId }) => {
  const translation = useContext(LocaleContext);
  const [clicked, setClickState] = useState(false);
  const handleButtonClick = () => {
    handleClick(module);
    setClickState(true);
  };

  return <>
    <GlobalBaseStyle />
    <CenterContent center={true}>
      <ThankYouContainer>
        <img src={loadImage(image)} alt={extract_text(title)}/>
        <ThankYou>{extract_text(title) || _get(translation, `${observation}.title`)}</ThankYou>
        <div hidden={!showUid}  className={`lefl-module-sub-title moduleSubTitle`}> {extract_text(title) || _get(translation, `${observation}.subtitle`)}{uId} </div>

        <ThankYouDesc>{extract_text(desc) || _get(translation, `${observation}.desc`,'')}</ThankYouDesc>
        <ThankYouButton onClick={handleButtonClick} />
      </ThankYouContainer>
    </CenterContent>
  </>
};

export default ServerComplete;
