import React, { useContext,Fragment } from 'react';
import { LocaleContext } from '../../context/LocalContext';
import _get from 'lodash.get';
import { PopUp, TimerFooter, PopModalBody, CancelButton } from './TimeExpireModelStyle';
import { useSelector } from 'react-redux';

const TimeExpireModel = ({ observation, handleNavigation }) => {

    const translation = useContext(LocaleContext);
    const { client } = useSelector((st)=> st.config)
    const isRtl = client?.isRtl;

    const onClick = () => {
        handleNavigation(1);
    }

    return (
        <Fragment>
            <PopUp className={isRtl?"rtl-modal":""} isOpen={true}>
                <PopModalBody>
                    <div className='lefl-module-title'>{_get(translation, `${observation}.title`)}</div>
                    <p className="rtl-text">{_get(translation, `${observation}.intruction`)}</p>
                </PopModalBody>
                <TimerFooter>
                    <CancelButton onClick={onClick}>{_get(translation, `${observation}.ok`,'OK')}</CancelButton>{' '}
                </TimerFooter>
            </PopUp>
        </Fragment>
    );
};

export default TimeExpireModel;