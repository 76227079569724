"use strict";

import getSymbolFromCurrency from "currency-symbol-map";
import _get from "lodash.get";
import numeral from "numeral";
import Slider from "rc-slider";
import React, {
  createRef,
  useContext,
  useEffect, useState
} from "react";
import {
  CenterContent, GlobalBaseStyle, Hint,
  Instruction, Question
} from "../../assets/styles/BaseStyle";
import { RcSliderStyle } from "../../assets/styles/RCSliderStyle";
import BaseSlider from "../../components/BaseSlider/BaseSlider";
import {
  BaseOption, BaseToolTip,
  BaseToolTipValue, SliderBase, SliderBaseContainer,
  SliderBaseLabel
} from "../../components/BaseSlider/BaseSliderStyle";
import SliderHandle from "../../components/BaseSlider/SliderHandle";
import { LocaleContext } from "../../context/LocalContext";
import { extract_text } from "../../helpers";
import CheckboxButton from "../../templates/GroupCheckboxButton/GroupCheckboxButton";
import { Button } from "./NumericalSliderStyle";
import { getClassName } from "../../lib/util";
const NumericalSlider = ({
  title,
  sub_title,
  value,
  defaultValue,
  min,
  max,
  marks,
  step,
  option1,
  option2,
  dynamicLable,
  handPointer,
  prefix,
  suffix,
  separator,
  onChange,
  help,
  onAfterChange,
  center,
  observation,
  other_option,
  preObs,
  sendOtherOptionData,
  otherOptionValue,
  instruction,
  previousValues,
  clientName
}) => {
  const translation = useContext(LocaleContext);


  const opt1Label = createRef();
  const opt2Label = createRef();

  const applyFormatting = (value) => {
    const evaluatedSuffix =
      typeof suffix === "function" ? suffix(value) : suffix;
    const formattedValue = separator
      ? numeral(value).format(`0${separator}0`)
      : value;

    let formatting = "";

    if (prefix) formatting += `${getSymbolFromCurrency(prefix) || prefix} `;
    formatting += formattedValue;
    if (evaluatedSuffix) formatting += ` ${evaluatedSuffix}`;

    return formatting;
  };

  const getLabel = (value) => {
    const increment = min < 1 ? 1 : 0;
    const valueLabel = _get(
      marks,
      `${value}.label`,
      _get(translation, `${observation}.option${value + increment}`)
    );
    let label;

    if (_get(option1, "label") && value === min) {
      label = opt1Label.current && opt1Label.current.textContent;
    } else if (_get(option2, "label") && value === max) {
      label = opt2Label.current && opt2Label.current.textContent;
    } else if (valueLabel) {
      label = valueLabel;
    } else {
      label = applyFormatting(value);
    }

    return `${label}`.trim();
  };
  const [preLable, setLabel] = useState(
    preObs && !help
      ? previousValues[preObs]?.label
      : _get(translation, `${observation}.question`)
  );
  const [sliderValue, setValue] = useState(value || defaultValue);
  const [tooltipValue, setTooltipValue] = useState(getLabel(sliderValue));
  const [moved, setSlideState] = useState(false);
  const [subsuffix, setSubsuffix] = useState("");
  const [other_option_value, setOtherOptionValue] = useState(null);
  useEffect(() => {
    setValue(otherOptionValue ? defaultValue : value || defaultValue);
    const label = getLabel(value || defaultValue);

    setTooltipValue(label);
    setSlideState(!!value);
    setOtherOptionValue(otherOptionValue || null);
  }, [observation]);

  const handleOtherOptionChange = (value) => {
    setOtherOptionValue(value);
    setValue(moved ? defaultValue : sliderValue);

    setSlideState(false);
    sendOtherOptionData(value, !value);
  };

  const handleSlide = (value) => {
    setOtherOptionValue(null);

    const label = getLabel(value);
    setSlideState(true);
    setValue(value);
    setTooltipValue(label);
    if (
      label !== _get(translation, `${observation}.option1`) &&
      label !== _get(translation, `${observation}.option2`) &&
      label !== _get(translation, `${observation}.option16`) &&
      label != 1
    ) {
      setSubsuffix(_get(translation, `${observation}.years`,""));
    }else if(label == 1){
      setSubsuffix(_get(translation, `${observation}.year`,""));
    } else {
      setSubsuffix("");
    }

    if (typeof onChange === "function") onChange(value.toString(), label);
  };

  const props = {
    onAfterChange,
    onChange: handleSlide,
    max,
    min,
    step,
    marks,
    onClick: () => console.log("clicked"),
    value: sliderValue,
    handle: SliderHandle,
    key: "slider",
  };

  const _opt1 =
    _get(option1, "label") ||
    _get(translation, `${observation}.option${min < 1 ? min + 1 : min}`) ||
    applyFormatting(min);
  const _opt2 =
    _get(option2, "label") ||
    _get(translation, `${observation}.option${min < 1 ? max + 1 : max}`) ||
    applyFormatting(max);

  const generateOtherOption = (type, _meta) => {
    switch (type) {
      case "button":
        const onClick = async () => {
          await onChange(
            _get(translation, `${observation}.buttonValue`),
            _get(translation, `${observation}.buttonValue`)
          );
          await onAfterChange(_get(translation, `${observation}.buttonValue`));
        };
        return (
          <Button onClick={onClick} >
            {_get(translation, `${observation}.buttonValue`)}
          </Button>
        );

      default:
        _meta.title = " ";
        _meta.sub_title = " ";
        _meta.options = _meta.options.map((option, index) => {
          option.label = _get(
            translation,
            `${observation}.answer_option${index + 1}`,
            _get(translation, `${observation}.answer_option`)
          );

          return option;
        });

        return (
          <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mx-auto">
            <CheckboxButton
              {..._meta}
              value={other_option_value || {}}
              observation={other_option.observation || observation}
              onChangeHandler={(other_option_value) =>
                handleOtherOptionChange(other_option_value)
              }
            />
          </div>
        );
    }
  };

  const evalRules = () => {
    if (preObs && eval(_get(translation, `${observation}.question`))) {
      if (dynamicLable) {
        return _get(
          translation,
          `${observation}.value${previousValues?.source_income?.value}`,
          eval(_get(translation, `${observation}.question`))
        );
      } else return eval(_get(translation, `${observation}.question`));
    }
  };
  const qEval = () => {
    try {
      if (eval(_get(translation, `${observation}.question`))) {
        return eval(_get(translation, `${observation}.question`));
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        return _get(translation, `${observation}.question`);
      }
    }
  };

  return (
    <>
      <GlobalBaseStyle />
      <RcSliderStyle />
      <CenterContent center={center}>
        {!handPointer && (
          <>
            <Question className={getClassName(title.alignment)} alignment={title.alignment}>
              {evalRules() ||
                extract_text(title) ||
                qEval() ||
                _get(translation, `${observation}.question`)}
            </Question>
            <Instruction
              visible={instruction}
              alignment={_get(instruction, "alignment", "left")}
            >
              {extract_text(instruction) ||
                _get(translation, `${observation}.instruction`, "")}
            </Instruction>
            <Hint
              className={getClassName(_get(sub_title, "alignment", "left"))}
              visible={sub_title}
              render_as={_get(sub_title, "as", "hint")}
              alignment={_get(sub_title, "alignment", "left")}
            >
              {extract_text(sub_title) ||
                _get(
                  translation,
                  `${observation}.hint`,
                  _get(translation, `${observation}.subquestion`)
                )}
            </Hint>
          </>
        )}
        <SliderBaseContainer pointer={handPointer}>
          <BaseToolTip visible={moved && !other_option_value}>
            <BaseToolTipValue>
              {tooltipValue + " " + subsuffix}
            </BaseToolTipValue>
          </BaseToolTip>
          <SliderBaseLabel>
            <BaseOption ref={opt1Label} align={"left"}>
              {_opt1}
            </BaseOption>
            <BaseOption ref={opt2Label} align={"right"}>
              {_opt2}
            </BaseOption>
          </SliderBaseLabel>
          <SliderBase>
            <Slider {...props} />
          </SliderBase>
        </SliderBaseContainer>
        {other_option &&
          generateOtherOption(other_option.type, other_option.meta)}
      </CenterContent>
    </>
  );
};

NumericalSlider.defaultProps = {
  title: "",
  sub_title: "",
};

export default BaseSlider(NumericalSlider);
