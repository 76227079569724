import React, { createContext } from 'react'

import en from '../locale/en.json';
import am from '../locale/am.json';
import om from '../locale/om.json';
import fr from '../locale/fr.json';
import es from '../locale/es.json';
import th from '../locale/th.json';
import ny from '../locale/ny.json';
import lg from '../locale/lg.json';
import rw from '../locale/rw.json';
import es_es from '../locale/es-es.json';
import el from "../locale/el.json";
import ur from "../locale/ur.json";
export const translations = {
  en,
  am,
  om,
  fr,
  es,
  th,
  ny,
  lg,
  rw,
  'es-es':es_es,
  el,
  ur
};

export const LocaleContext = createContext({});
