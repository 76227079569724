import React, { useContext, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { GlobalBaseStyle, Hint, Question } from '../../assets/styles/BaseStyle';
import { extract_text } from '../../helpers';
import _get from 'lodash.get';
import { LocaleContext } from '../../context/LocalContext';
import { ContentWrapper, DummyModal, Option } from './FillAmountStyle';

const FillAmount = ({ title, sub_title, observation, symbl, onChangeHandler, handPointer, previousValues }) => {

    const translation = useContext(LocaleContext);
    const [amountFormate, setFormate] = useState('');
    const formatNumber = (n) => {
        return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const formatCurrency = (input_val, symbl = '$') => {
        if (input_val === "") {
            setFormate('');
            return symbl + 0;
        }
        if (input_val.indexOf(".") >= 0) {
            let decimal_pos = input_val.indexOf(".");
            let left_side = input_val.substring(0, decimal_pos);
            let right_side = input_val.substring(decimal_pos);
            left_side = formatNumber(left_side);
            right_side = formatNumber(right_side);
            right_side = right_side.substring(0, 2);
            input_val = symbl + left_side + "." + right_side;
        } else {
    
            input_val = formatNumber(input_val);
            if (input_val === "") input_val = 0;
            input_val = symbl + input_val;
        }
        setFormate(input_val);
    }

    useEffect(() => {
        if(previousValues) {
            let preOb =  `${previousValues[observation]}`;
            if(preOb !== 'undefined') {
                let prValue = `${previousValues[observation].value}`;
                setFormate(prValue);
            }
        }
    }, [])

    useEffect(() => {
        if (typeof onChangeHandler === "function") {
            let int = +amountFormate.replace(/[,$]/g, '');
            onChangeHandler({ value: amountFormate, itsDone: int !== 0 })
        }
    }, [amountFormate])

    const manageValue = (value, status) => {
        let int;
        if (status === true) {
            int = +value.replace(/[,$]/g, '');
            int = int.toString();
            int = int.substring(0, 15);
            int = parseInt(int);
            formatCurrency((int + 1).toString(), symbl)
        } else if (status === false) {
            int = +value.replace(/[,$]/g, '');
            int = int.toString();
            int = int.substring(0, 15);
            int = parseInt(int);
            if (int !== 0) formatCurrency((int - 1).toString(), symbl)
        } else {
            int = value.replace(/[,$]/g, '');
            int = int.substring(0, 15);
            formatCurrency((int).toString(), symbl)
        }
    }

    return (
        <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
            <GlobalBaseStyle />
            
            {
                !handPointer && <>
                <div>
                    <Question className="my-4" alignment={title?.alignment}>{extract_text(title) || _get(translation, `${observation}.title`, '')}</Question>
                    <Hint visible={sub_title} render_as={sub_title?.as} alignment={sub_title?.alignment}>{
                        extract_text(sub_title) || _get(translation, `${observation}.sub_title`, '')}</Hint>
                </div>
                <ContentWrapper>
                    <input placeholder="$0" className="int_input" type="text" value={amountFormate}
                        onChange={({ target }) => manageValue(target.value, null)} />
                    <button className="circle" onClick={() => manageValue(amountFormate, false)}>-</button>
                    <button className="circle" onClick={() => manageValue(amountFormate, true)}>+</button>
                </ContentWrapper>
            </>
            }
            {
                handPointer && <>
                    <DummyModal>
                    <Option  pointer={true}></Option>
                        <ContentWrapper>
                            <input className="int_input dummySt" type="text" readOnly value='$0' />
                            <button className="circle">-</button>
                            <button className="circle">+</button>
                        </ContentWrapper>
                    </DummyModal>
                </>
            }

        </div >
    );
};

export default FillAmount;