import React, { useEffect, useState } from "react";
import { drawStacks, getCurrentStep, isRtl, isTokenExpired, sessionExpired } from "../../lib/util";
import { setActiveIndex, setCurrentModule, setCurrentScreen, setModuleList, setProgressBar } from '../../redux/module/actionCreator';
import { setCallbacks, setClient, setTheme } from '../../redux/config/actionCreator';
import { setError, setExpired, setLoading, setStartSession, setSurveyCompleted, setSurveyCancelled, setSurveyExpired } from '../../redux/authentication/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { COOKIE, RETRY, URL } from "../../helpers/constant";
import CookieHelper from "../../helpers/cookieHelper";
import { DataService } from "../../config/dataService/dataService";
import JwtHelper from "../../helpers/jwtHelper";
import Modules from "../../components/PlayerContainer/modules";
import PlayerContainer from "../../components/PlayerContainer/index";
import LoadingScreen from "../../components/LoadingScreen";

function TokenIntegration() {
  const { loading, surveyCancelled, surveyCompleted, surveyExpired } = useSelector(State => State.auth);
  const { callbacks } = useSelector(State => State.config);
  const { token: jwtToken } = useParams()
  const [data, setData] = useState(null)
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    const userLoginAndGetConfig = async () => {
      // read token
      const decoded = new JwtHelper(jwtToken).decoded
      if (isTokenExpired(decoded.exp)) {
        console.error('expired')
        dispatch(setExpired(true));
        const { uid, integration_id } = decoded
        history.push({ pathname: '/session-expired', state: { uid, integration_id } })
        return
      }

      CookieHelper.set(COOKIE.TOKEN, jwtToken, {
        expires: new Date(decoded.exp * 1000)
      })
      CookieHelper.set(COOKIE.USER, decoded)
      try {
        let resp = await DataService.post(
          "psychometrics/start-session",
          {
            integrationId: decoded.integration_id,
            uid: decoded.uid,
            companyId: decoded.company_id
          },
        );
        const missing = resp.missing?.self_image?.map(j=>j.observation)
        setData({ ...resp, uid: decoded.uid, missing })
        dispatch(setClient({ languageCode: resp?.languageCode,isRtl:isRtl(resp?.languageCode),languageOptions: resp?.languageOptions,userLanguageCode:resp?.userLanguageCode}));

      } catch (err) {
        console.log(err)
        if (err.code) {
          sessionExpired(true)
        }
      }
    }
    userLoginAndGetConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (surveyCompleted) {
      if (!callbacks.successUrl) {
        // history.push(callbacks.successUrl)
      } else {
        window.location.href = callbacks.successUrl
      }
    }
  }, [surveyCompleted]);

  useEffect(() => {
    if (surveyCancelled) {
      if (!callbacks.failureUrl) {
        history.push(URL.FAILURE_URL)
      } else {
        window.location.href = callbacks.failureUrl
      }
    }
  }, [surveyCancelled]);
  useEffect(() => {
    if (surveyExpired) {
      if (!callbacks.expiredUrl) {
        history.push(URL.EXPIRED_URL)
      } else {
        window.location.href = callbacks.expiredUrl
      }
    }
  }, [surveyExpired]);

  useEffect(() => {
    if (!data) {
      dispatch(setError("Data not found"));
      return;
    }

    const callbacks = data.callback;
    dispatch(setCallbacks(callbacks))

    const handleDemoCompany = () => {
      if (data.isDemoCompany) {
          history.push('/blocked');
          return true;
      }
      return false;
    }
    const handleCurrentStep = () => {
      if (data.currentStep?.screen === "survey_complete") {
          dispatch(setSurveyCompleted(true));
          history.push('/already-taken');
          return true;
      }
      return false;
    }
    const handleStatus = () => {
      const { status } = data.application || {};
      switch (status) {
          case 'INCOMPLETE':
              history.push('/unauthorized');
              return true;
          case 'EXPIRED':
              dispatch(setSurveyExpired(true));
              return true;
          case 'COMPLETED':
              dispatch(setSurveyCompleted(true));
              history.push('/already-taken');
              return true;
          case 'CANCELLED':
              dispatch(setSurveyCancelled(true));
              return true;
          default:
              return false;
      }
  };
    if (handleDemoCompany() || handleStatus() || handleCurrentStep()) {
        return;
    }
    
    const { pendMods, index } = filterModules()
    dispatch(setLoading(false));
    dispatch(setStartSession(true));
    initializePlayerModules(pendMods, index)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const initializePlayerModules = (pendingModules, _index) => {
    if (pendingModules.length < 1) return
    let modList = [];
    const { status } = data.application || {};
    pendingModules.forEach((m) => {
      let props = Modules[m.module]
      
      if (status === 'RETRY') {
        if (m.module === 'self_image' && data?.missing) {
           // RETRY case if we any any missing self image module then retry only missing observations
            props.screens = props?.screens?.filter(s => data.missing.includes(s.observation));
        } else if (m.module === 'finding_patterns') {
            // RETRY case we dont need to show skip screen
            props.screens = props?.screens?.filter(s => s.observation !== 'rt_gamepref');
        }
      }
      modList.push({
        props,
        name: m.title,
      });
    });
    let currentScreen = 0
    if (data.currentStep) {
      currentScreen = Modules[pendingModules[0].module].screens.findIndex(x => x.observation === data.currentStep.screen);
      if (!(Modules[pendingModules[0].module].screens[currentScreen + 1]?.observation)) {
        modList.shift();
        currentScreen = 0;
      } else currentScreen++;
    }
    if (data?.rt_gamepref) {
      Modules.finding_patterns.screens.splice(0, 1);
    }
    
    data.currentStep = getCurrentStep(pendingModules, currentScreen, status)
    dispatch(setActiveIndex(0));
    dispatch(setModuleList(modList));
    const toalCount = data.modules ? [...new Set(data?.modules.map(item => item.module))].length : 9
    const completedCount = toalCount - modList.length || 0
    dispatch(setProgressBar({ total: toalCount, completed: completedCount, stacks: drawStacks(toalCount, completedCount) }));
    dispatch(setCurrentModule(data.currentStep.module));
    dispatch(setCurrentScreen(data.currentStep.screen));


    const theme = { ...data.theme };
    delete theme.companyName;
    dispatch(setTheme(theme));

    const { languageCode, currencyCode, type, uid, contents } = data;
    const client = {
      languageCode,
      currencyCode,
      type,uid,contents,
      clientName: data.theme.companyName,
      showCompletionPage: data?.callback?.showCompletionPage ?? true,
      createdAt : data?.createdAt,
      isRetry: data.application.status=== RETRY,
      isRtl:isRtl(languageCode),
      languageOptions:data?.languageOptions,
      userLanguageCode:data?.userLanguageCode
    };
    dispatch(setClient(client));
    dispatch(setLoading(false));
    dispatch(setStartSession(true));
  }

  const filterModules = () => {
    if (!data.currentStep) return { pendMods: data.modules, index: 0 };
    else {
      let index = data.modules.findIndex(
        (x) => x.module === data.currentStep.module
      );
      if (
        data.currentStep.module == "finding_patterns" &&
        data.currentStep.screen == "rt_gamepref"
      )
        index++;
      else if (
        data.currentStep.module == "finding_patterns" &&
        data.currentStep.screen == "greatJob" &&
        data.rt_gamepref
      )
        index = data.modules.length - 2;
      return { pendMods: data.modules.slice(index), index: index };
    }
  };


  return (
    <div>
      {loading ? <LoadingScreen /> : <PlayerContainer />}
    </div>
  );
}

export default TokenIntegration;
