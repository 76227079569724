import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { GlobalBaseStyle, Question } from "../../assets/styles/BaseStyle";
import { Container, ImgWrapper, ScoreLabel } from "./HoverSliderStyle";
import { extract_text } from "../../helpers";
import _get from "lodash.get";
import { LocaleContext } from "../../context/LocalContext";
import { getClassName } from "../../lib/util";

const HoverSlider = ({ title, observation, maxImgs = [], onChangeHandler }) => {
  const translation = useContext(LocaleContext);

  const [activeIndex, setIndex] = useState(0);
  const [imgArray, setArray] = useState(maxImgs);

  const onMouseEnter = (index) => {
    const i = index + 1;
    let imageActive = true;
    if (activeIndex == 1 && index == 0) {
      imageActive = false;
    }
    setArray([
      ...Array.from({ length: i }, () => imageActive),
      ...Array.from({ length: maxImgs.length - i }, () => false),
    ]);
  };
  useEffect(() => {
    onMouseLeave(true);
  }, [activeIndex]);

  const onClick = (index) => {
    if (activeIndex == 1 && !imgArray[activeIndex - 1] && index == 0) {
      setIndex(index);
      onChangeHandler({ activeIndex: 0 });
    } else {
      setIndex(index + 1);
      onChangeHandler({ activeIndex: (index + 1).toString() });
    }
  };

  const onMouseLeave = (flag = false) => {
    let imageActive = true;
    if (activeIndex == 0) imageActive = false;
    if (activeIndex || flag)
      setArray([
        ...Array.from({ length: activeIndex }, () => imageActive),
        ...Array.from({ length: maxImgs.length - activeIndex }, () => false),
      ]);
    else setArray([...maxImgs]);
  };

  return (
    <Fragment>
      <GlobalBaseStyle />
      <Container>
        <Question className={getClassName(title.alignment)} alignment={title.alignment}>
          {extract_text(title) || _get(translation, `${observation}.title`)}
        </Question>
        <ScoreLabel className="directionRtl">{activeIndex} {extract_text(title) || _get(translation, `${observation}.outOf`)} 10 </ScoreLabel>
        <ImgWrapper onMouseLeave={onMouseLeave}>
          {imgArray.map((img, i) => (
            <div
              key={i}
              onClick={() => onClick(i)}
              onMouseEnter={() => onMouseEnter(i)}
            >
              <svg
                viewBox="0 0 22 66"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.06475 50.4944C4.06475 47.0526 4.07333 43.6107 4.04758 40.1688C4.04472 39.9009 3.87881 39.5134 3.66428 39.3881C1.26147 37.9777 0.0171584 35.912 0.0114374 33.1283C0.00285594 29.2106 -0.020028 25.2928 0.0171584 21.378C0.0572052 17.2437 2.93485 14.4572 7.10544 14.4486C9.67988 14.4429 12.2543 14.4429 14.8288 14.4486C19.028 14.4572 21.9828 17.4004 21.9914 21.5803C22 25.3783 21.9771 29.1764 21.9971 32.9744C22.0143 35.8664 20.8529 38.0461 18.2613 39.4451C18.064 39.5505 17.9524 39.9807 17.9524 40.2628C17.9352 46.7448 17.941 53.224 17.9352 59.7061C17.9352 60.1563 17.9095 60.6093 17.8523 61.0538C17.5548 63.3845 16.2676 64.9971 14.0221 65.6069C12.0598 66.1425 9.98309 66.1169 8.00936 65.6211C5.64373 65.0285 4.13054 63.0397 4.08763 60.6064C4.03042 57.2358 4.07333 53.8651 4.07333 50.4944C4.07333 50.4944 4.07047 50.4944 4.06475 50.4944Z"
                  fill={img ? "#31B2D0" : "#ACACAC"}
                />
                <path
                  d="M10.8327 7.92075e-05C14.2881 -0.0170163 17.0857 2.73536 17.1 6.163C17.1143 9.5764 14.3167 12.3915 10.8956 12.4086C7.46302 12.4257 4.65688 9.64764 4.64544 6.22284C4.63114 2.76955 7.36862 0.0171747 10.8327 7.92075e-05Z"
                  fill={img ? "#31B2D0" : "#ACACAC"}
                />
              </svg>
            </div>
          ))}
        </ImgWrapper>
      </Container>
    </Fragment>
  );
};

export default HoverSlider;
