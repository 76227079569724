'use strict';

import React, { Fragment, useContext } from 'react';

import { GlobalBaseStyle, CenterContent } from '../../assets/styles/BaseStyle';
import { InstructionImage } from './MultipleInstructionStyle';
import { Question} from '../../assets/styles/BaseStyle';
import {extract_text} from '../../helpers';
import {LocaleContext} from '../../context/LocalContext';
import {loadImage} from '../../helpers/image_loader';
import _get from 'lodash.get';

const MultipleInstructionScreen = ({ observation, image, instruction, hint,texts }) => {
  
  
  const translation = useContext(LocaleContext);
  const imageType = typeof image;
  const list =  _get(translation, `${observation}.texts`) || texts;
  
  return (
    <Fragment>
      <GlobalBaseStyle />
      <CenterContent center={true}>
     { list.map((text, index) => (
       <Question alignment={ _get(instruction, 'alignment', 'center')}>
          {
            extract_text(text) ||
            _get(translation, `${observation}.texts[${index}]`)
          }
        </Question>))}
        <InstructionImage width={imageType === 'object' ? image.width : 100}>
          <img src={loadImage(imageType === 'object' ? image.source : image)} alt=""/>
        </InstructionImage>
      </CenterContent>
    </Fragment>
  )
};

MultipleInstructionScreen.defaultProps = {
  image: {
    width: 100,
    source: ''
  },
  texts:[]
};


export default MultipleInstructionScreen
