import styled from 'styled-components'

export const Question = styled.div`
  margin-bottom: 10px;
  font-size: 18px;
  text-align: ${({ alignment }) => alignment};
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 2px;
}
 @media (min-width:320px) and (max-width:426px){
    font-size:14px; 
}
`;


export const Text = styled.div`
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;
`;

export const Boxs = styled.div`
display:flex;
justify-content:center;
align-items:center;
`;

export const Body = styled.div`
    margin:20px auto;
    background: #C0CCD7;
    width: 100px;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    position:relative;
    @media (min-width:320px) and (max-width:426px){
         width: 81px;
         margin:9px auto 20px auto;
    }
`;

export const Box = styled.div`
margin:auto;
display:flex;
justify-content:center;
align-items:center;
background-color:white;
height: 35px;
border-radius: 5px;
text-align: center;
padding: 5px;
font-size: 25px;
border:1px solid black;
@media (min-width:320px) and (max-width:426px){
    height: 24px;
    font-size: 19px;

}
`;


export const TrukImg = styled.div`
    display: block;
    justify-content: center;
    margin: 0 50px 50px 50px;
    align-items:center;
    .mainImg{
        width: 566px;
        margin: auto;
        img{
            width:100%;
        }
    }
    .skipImg{
        width: 128px;
        margin: auto;
        height: 32px;
        img{
            width:100%;
        }
    }

@media (min-width:425px) and (max-width:768px){
    .mainImg{
         width: 100%;
        img{
            width:100%;
        }
    }

}

@media (min-width:320px) and (max-width:426px){
    margin:-17px 10px 0px 10px;
    .mainImg{
        width: 100%;
        img{
            width: 100%;
            max-width: 100%;
        }
    }
}
    ${({ containerName }) => containerName === 'balloon' && `
        .mainImg{
            width: 300px;
            height: 300px;
        }
    `}

`;

export const GoButton = styled.div`
    text-align:center !important;
    img {
        cursor: pointer;
        margin:auto 25px;
        width: 130px;
    }
    @media (min-width:320px) and  (max-width:767px){
        img {
            width:90px;
        }
    }
`;



export const Button = styled.button`

margin:30px auto;
background: ${({ theme }) => theme.colors.primary};
font-size:18px;
text-align: center;
width: 30%;
border-radius: 50px;
padding: 10px 0px;
color:#fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
@media (min-width:320px) and  (max-width:767px){
	width:75%;
	margin:15px 15px;

}
@media (min-width:768px) and (max-width:991px){
    width: 40%;
}
@media (min-width:320px) and (max-width:426px){
    font-size: 14px;
    padding: 5px 0px;
    margin: 5px 15px 15px 15px;
}
`