import styled from 'styled-components';
import {  ModalHeader, Modal } from 'reactstrap';

export const Container = styled.div`
width: 10%;
position: relative;
text-align: end;
margin-top: 0;
@media (min-width:320px) and  (max-width:767px){
	width:20%;
  margin:0px 15px
}
`;



export const Button = styled.button`
background: transparent !important;
font-size:14px;
font-weight: 500;
text-align: center !important;
color:#000;
border: none;
outline: none;
box-shadow: none !important;
&:hover {
background: rgba(0, 0, 0, 0.05) !important;
}
&:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }

`;

export const ModalContainer = styled(Modal)`
  [class~="modal-content"] {
		border: 2px solid 
    border-radius: .3rem;
	}
`;

export const Header = styled(ModalHeader)`
  border: 0;
  padding: 10px 15px;
`;


export const Buttons = styled.button`

margin:30px 0;
background: ${({ theme }) => theme.colors.primary};
font-size:18px;
text-align: center !important;
width: 30%;
border-radius: 50px;
padding: 10px 0px;
color:#fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
@media (min-width:320px) and  (max-width:767px){
	width:100%;
	margin:10px 15px
}
`;

export const OptionList = styled.div`
  max-height: 210px;
  overflow-y: auto;

  [class~="list-group-item"] {
		cursor: pointer;
	}
`;

export const Group = styled.div`
  text-align: left;
    margin: 50px auto;
  width: 67%;
  margin: 29px auto 25px auto;
  @media (min-width:481px) and (max-width:767px){
    width: 68%;
  }
  @media (min-width:320px) and (max-width:480px){
    width: 100%;
  
`;