import _get from 'lodash.get'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalBaseStyle, Question } from '../../assets/styles/BaseStyle'
import HelperModal from '../../components/HelperModal/HelperModal'
import RadioButton from '../../components/RadioButton/RadioButton'
import RavenPattern from '../../components/RavenPattern/RavenPattern'
import { LocaleContext } from '../../context/LocalContext'
import setTheme from '../../context/ThemeProvider'
import { extract_text } from '../../helpers'
import { fpLoadImage } from '../../helpers/image_loader'
import useThemeConfig from '../../hooks/useThemeConfig'
import { Img, ImgWraper, QuestionImage, Radio } from './FindPatternStyle'

const FindPattern = ({
  valueVisible,
  v2,
  theme,
  help,
  questionImage,
  title,
  jump,
  hideHalpNavigation,
  helpImgs,
  options,
  observation,
  jumpIndex,
  handPointer,
  onChangeHandler,
  optionValue,
  handleNavigation,
  value,
  fpVariant,
  variant,
  ...props
}) => {

  const translation = useContext(LocaleContext)
  const themeConfig = useThemeConfig()

  const [showHelper, setHelper] = useState(false)
  const [isSelect, setSelect] = useState(false)
  const [exitGame, setExitGame] = useState(false)
  const [imgClick, setImgClick] = useState(false)
  const [idu, setIdu] = useState(false)

  const meta = { fpVariant, helpImgs, title, options, hideHalpNavigation }

  const imgClicked = answer => {
    onChangeHandler({ value: answer, label: observation, idu: idu })
    setImgClick(true)
  }
  useEffect(() => {
    if (observation && observation === 'rt_level03') {
      if (
        props.previousValues.rt_level01 === undefined &&
        props.previousValues.rt_level02 === undefined
      ) {
        handleNavigation(5)
      }
    }
    setIdu(false)
  }, [observation])

  useEffect(() => {
    imgClick && handleNavigation(1)
    setImgClick(false)
  }, [imgClick])

  useEffect(() => {
    if (showHelper)
      setIdu(true)
  }, [showHelper])

  const setHelperModalState = ({ open }) => {
    setHelper(open)
    setSelect(open)
  }
  useEffect(() => {
    if (exitGame) {
      imgClicked({ skpGame: true })
      handleNavigation(jump)
    }
  }, [exitGame, imgClicked, handleNavigation, jump])

  return (
    <Fragment>
      <GlobalBaseStyle />
      <ThemeProvider theme={setTheme(theme || themeConfig)}>
        <QuestionImage className="lefl-module-title" v2={v2} handPointer={handPointer}>
          <img className='QuestionImage' src={fpLoadImage(fpVariant, questionImage,variant)} alt={questionImage} />
        </QuestionImage>
        <div hidden={handPointer}>
          <div style={{ marginBottom: '25px' }}>
            <Question className="lefl-module-title" alignment={title.alignment}>
              {extract_text(title) || _get(translation, `${observation}.title`)}
            </Question>
          </div>
          {v2 ? (
            <ImgWraper v2={v2} className={`${!questionImage ? 'my-5' : ''} imgGame`}>
              {options.map(({ img, correct }, i) => {
                const { label = '' } =
                  optionValue[i] || _get(translation, `${observation}.optionValue[${i}]`, [])
                return (
                  <Img v2={v2} key={i} style={{}}>
                    {!v2 && <p>{String.fromCharCode(65 + i)}</p>}
                    <img
                      onClick={() => imgClicked({ correct, label, value: i + 1 })}
                      style={{ cursor: 'pointer' }}
                      src={fpLoadImage(fpVariant, img, variant)}
                      alt={'fp' + fpVariant + img}
                    />
                    {v2 && valueVisible == undefined && <p className='labelFont'>{label}</p>}
                  </Img>
                )
              })}
            </ImgWraper>
          ) : (
            <RavenPattern variant={variant} fpVariant={fpVariant} options={options} observation={observation} imgClicked={imgClicked} />
          )}
        </div>
        <Radio hidden={handPointer}>
          {showHelper && (
            <HelperModal
              ready_state={{}}
              help={help}
              screen={meta}
              type={'find_pattern'}
              observation={observation}
              journey_state={{}}
              showHelper
              isOpen={true}
              exitGame={e => setExitGame(e)}
              helpModalStateHandler={state => setHelperModalState(state)}
            />
          )}
          {_get(translation, `${observation}.option`, false) && (
            <RadioButton
              onClick={() => setHelper(true)}
              pointer={handPointer}
              selected={isSelect}
              label={_get(translation, `${observation}.option`)}
              className="directionRtl"
            />
          )}
        </Radio>
      </ThemeProvider>
    </Fragment>
  )
}

export default FindPattern
