"use strict";
import CryptoJS from "crypto-js"
import _merge from 'lodash.merge'
import _cloneDeep from 'lodash.clonedeep'

import language from './language'
import CookieHelper from "../helpers/cookieHelper";
import Modules from "../components/PlayerContainer/modules";

const { translate } = language

const baseScreenDefinition = {
    nextButton: {
        visible: true,
        enabled: false
    },
    prevButton: {
        visible: true,
        enabled: true
    },
    // screenTimers: [],
    // observations: [],
    // helper: false,
    // countdownTimer: {
    //     // visible: false, //showTimer
    //     time: 0,
    //     enableControl: false,
    //     enableExtension: false
    // },
    // screenConfig: {}
}

export const composeScreenParams = (props) => {
    return _merge(_cloneDeep(baseScreenDefinition), {
        ...props,
    })
}

export const getModuleScreens = (screens, screenLoader) => {
    const screenPromises = []

    screens.forEach(screen => {
        screenPromises.push(screenLoader(screen))
    })

    return Promise.all(screenPromises)
        .then(screens => {
            return screens.filter(screen => screen !== null)
        })
}

export const generateGroupOptions = (options) => {
    const start = options.start || 0;
    const end = options.end || 0;
    const answerOptions = [];

    if (!options.prefix) {
        console.warn('Missing checkbox options prefix');
    }

    for (let i = start; i <= end; i++) {
        const option = {
            value: i,
            label: `${options.prefix}${i}`
        };

        if (options.exclusive == i) {
            option.exclusive = true;
        }

        answerOptions.push(option);
    }

    return answerOptions;
}

export const ensureArray = (param) => {
    return Array.isArray(param) ? param : [param]
}

export const sliderTranslator = (translationTag) => {
    return (count) => translate(translationTag, { plural: { count } })
}

export const encrypt = (string) => {
    return CryptoJS.AES.encrypt(string, 'secret').toString();
}
export const decrypt = (cipherText) => {
    let bytes = CryptoJS.AES.decrypt(cipherText, 'secret');
    return bytes.toString(CryptoJS.enc.Utf8);
}
export const isTokenExpired = (exp) => {
    return Date.now() >= exp * 1000
}
export const sessionExpired = () => {
    CookieHelper.clearAllCookie()
}
export const drawStacks = (nmbr = 9, completed = 0) => {
    return Array.from({ length: nmbr }, (v, i) => completed > i);
}
export const activeScreenIndex = (module, isRetry , index) => {
   return index > 0 ? index : (isRetry && module.module === 'finding_patterns' ? 0: 0)
}
export const getCurrentStep = (pendingModules, currentScreen, status)=>{
    let currentM = pendingModules[0].module 
    let screens = Modules[currentM].screens
     // RETRY case we dont need to show skip screen
    if(status=== 'RETRY' && currentM === 'finding_patterns'){
        screens = screens?.filter(s=> 'rt_gamepref'!== s.observation )
    }
    return  {
      module: currentM,
      screen: screens[currentScreen].observation,
    }
}
// swapping alignment
export const getClassName = (alignment) => {  
    if (alignment === "center") return "";
    return "rtl-text"; 
  };

// Function to check if a language uses a right-to-left rtl script

export const isRtl = (languageCode) => languageCode === "ur"; // here we can Add any rtl language codes (Arabic,Hebrew) for align right to left

