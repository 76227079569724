import styled from 'styled-components';

import hand from '../../assets/images/hand.svg'

export const CheckboxWrapper = styled.div`
  ${({ type }) => type === 'image' && `
    align-content: stretch;
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-top: 10px;
    position: relative;
    width: 100%;
  `};
`;

export const Checkbox = styled.input`
  top: 0;
  left: 0;
  width: 4.125em;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  height: 100%;
`;

export const Option = styled.div`
  position: relative;
  width: 100%;
  background-color: #F0F3F7;
  margin-top: .625em;
  margin-bottom: 0;
  display: flex;
  flex-direction: ${({ type }) => type === 'image' ? 'column' : 'row'};
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  border-radius: 4px;
  
  :after {
    ${({ pointer, theme }) => pointer && `
      content: '';
      display: flex;
      width: 70px;
      height: 80px;
      position: absolute;
      z-index: 1;
    `}
    
    top: ${({ type }) => type === 'image' ? '20%' : '60%'};
    left: ${({ type }) => type === 'image' ? '25px' : '25px'};
    ${({ hide_hands }) => !hide_hands ? `background: url(${hand}) center center no-repeat;` : 'none;'}
    path: { fill: orange; }
  }
`;

export const DummyLabel = styled.label`
  border-color: green;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export const Label = styled.label`
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width:320px) and (max-width:427px) {
    ${({ fontSize }) => fontSize && `font-size:${fontSize}`}
  }
`;

export const Span = styled.span`
  display: inline-block;
  min-width: 1.625em;
  max-width: 1.625em;
  height: 1.625em;
  margin: .5em 1.25em;
  border: .125em solid ${({ theme }) => theme.colors.primary};
  border-radius: .8125em;
  background: #FFF;
  vertical-align: middle;
  text-align: center;
  line-height: 0;
    
  
  ${({ selected, theme }) => selected && `
    span {
      display: inline-block;
      width: .999em;
      height: .999em;
      border-radius: .4995em;
      background: ${theme.colors.primary};
      margin-top: .188em;
      
    }
  `}
`;