/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'

import TextInput from '../TextInput/Input';

import { GlobalBaseStyle, CenterContent, Question, Hint, Instruction } from '../../assets/styles/BaseStyle';
import { Group, Image } from './GroupRadioButtonStyles'
import RadioButton from '../../components/RadioButton/RadioButton'
import { extract_text } from '../../helpers';
import { LocaleContext } from '../../context/LocalContext';

import { loadImage } from '../../helpers/image_loader';
import _get from 'lodash.get';

import shuffleArray from '../../lib/shuffleArray';
import { getClassName } from '../../lib/util';
const GroupRadioButton = ({ fontSize,
  observation, title, options, center, other_option,
  handPointer, value, add_to_state, defaultValue, image,
  onChangeHandler, sub_title, instruction, shuffle=true}) => {

  const translation = useContext(LocaleContext);
  const componentRef = useRef(true);

  const [selected, setSelected] = useState(_get(value, 'value', null));
  const [label, setLabel] = useState(null);
  const [other_option_value, setOtherOptionValue] = useState(_get(value, `other_option_value.${_get(other_option, 'observation')}`));
  const [sequence, setSequence] = useState(_get(value, 'sequence', []));
  useEffect (()=>{
    if(options&&shuffle){
      options = shuffleArray(options)
    }
  },[])
  useEffect(() => {
    setSelected(_get(value, 'value', defaultValue || null));
    setLabel(_get(value, 'label', null));
    setSequence(_get(value, 'sequence', []));
    setOtherOptionValue(_get(value, `other_option_value.${_get(other_option, 'observation')}`))
  }, [observation]);

  useEffect(() => {
    if (!componentRef.current && selected) {
      if (typeof onChangeHandler === 'function') {
        const other_option_invalid = !other_option_value || other_option_value.length < 1;
        const other_option_selected = selected === _get(other_option, 'value', 'other_option');

        if (other_option_invalid && other_option_selected) {
          onChangeHandler(null)
        } else {
          const state = {};
          if (add_to_state) {
            state[typeof add_to_state === 'string' ? add_to_state : observation] = selected;
          }

          onChangeHandler({
            value: selected?.toString(),
            other_option_value: other_option_selected ? { [other_option.observation]: other_option_value } : null,
            sequence,
            label
          }, state)
        }
      }
    } else {
      componentRef.current = false
    }
  }, [selected, other_option_value]);

  const handleSelect = (value, label) => {
    setSelected(value);
    setLabel(label);
    setSequence(sequence => {
      if (value !== sequence[sequence.length - 1]) sequence.push(value);
      return sequence
    });
  };

  const generateOptions = () => {
    const defaultOptions = [...options];
    const other_option_order = (other_option && other_option.order) === 0 ? other_option.order : defaultOptions.length;

    if (other_option && !handPointer) defaultOptions.splice(other_option_order, 0, {
      value: _get(other_option, 'value', 'other_option'),
      label: other_option.label
    });

    return defaultOptions.map((item, index) => {
      const is_selected = selected === item.value;

      return <RadioButton
        key={index}
        fontSize={fontSize}
        identifier={index}
        onClick={() => handleSelect(item.value, item.label || _get(translation, `${observation}.option${item.value}`))}
        pointer={handPointer && is_selected}
        selected={is_selected}
        label={item.label || _get(translation, `${observation}.option${item.value}`)}
        className={"directionRtl"}
      />
    });
  };

  const otherOptionInput = () => {
    if (other_option.type) {
        return <TextInput
          {...other_option.meta}
          value={other_option_value}
          observation={other_option.observation}
          onChangeHandler={(input_value, observation, input_error) => setOtherOptionValue(input_error ? null : input_value)}
        />;
    }
  };

  return <>
    <GlobalBaseStyle />
    <CenterContent center={center}>
    <Question className={getClassName(title.alignment)} alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.title`)}</Question>

      {image && <Image><img src={loadImage(image)} alt={extract_text(title) || _get(translation, `${observation}.question`)} /></Image>}
      {
        !handPointer && <>
          <Question className={getClassName(title.alignment)}  alignment={title.alignment}>{extract_text(title) || _get(translation, `${observation}.question`)}</Question>
          <Instruction
            visible={instruction}
            alignment={_get(instruction, 'alignment', 'left')}
          >
            {extract_text(instruction) || _get(translation, `${observation}.instruction`, '')}
          </Instruction>
          <Hint className={getClassName(_get(sub_title, 'alignment', 'left'))}  fontSize={fontSize}
            visible={sub_title} render_as={_get(sub_title, 'as', 'hint')}
            alignment={_get(sub_title, 'alignment', 'left')}
          >
            {extract_text(sub_title) || _get(translation, `${observation}.hint`, _get(translation, `${observation}.subtitle`))}
          </Hint>
        </>
      }
      <Group>
        {generateOptions()}
        {selected === _get(other_option, 'value', 'other_option') && otherOptionInput()}
      </Group>
    </CenterContent>
  </>
};

GroupRadioButton.defaultProps = {
  options: [],
  value: null,
  handPointer: false,
  sub_title: '',
  title: ''
};

GroupRadioButton.propTypes = {
  value: PropTypes.object,
  handPointer: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func
};

export default GroupRadioButton