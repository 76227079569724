import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const Body = ({ children }) => {
  const { client } = useSelector((st)=> st.config)

// The `isRtl` value is derived from `tokenIntegration` in the `client` object.
// It determines whether the selected language uses an RTL (Right-to-Left) script or an LTR (Left-to-Right) script.
// The `isRtl` helper function (defined in `util.js`) is used to verify if a language is RTL.
// Additional RTL language codes can be added to the `isRtl` function in `util.js` for future support.s
  const isRtl = client?.isRtl;  
  return (
    <div
      className={`App ${isRtl ? "rtl" : ""}`}
    >
      {children}
    </div>
  );
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;