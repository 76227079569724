import styled from "styled-components";
export const GameOptionButton = styled.div`
  display:flex;
  flex-direction:column;
  text-align: center;
  align-items:center;
 
  margin-bottom:50px;
  img {
    max-width: 130px;
    width:100%;
    // margin:20px auto;
    border: 1px solid #957A47;
    border-radius: 5px;

    @media (max-width: 768px) {
      margin:2px auto;
    }
    @media (max-width: 360px) {
      // height: 30px !important;
    }
  }
  .button_otp {
    border-radius: 20px;
    width: 140px;
    padding:8px 10px;
    text-align: center;
    border: none;
    outline: none;
    @media (max-width: 768px) {
      width:100%;
      padding:4px 5px;
      font-size:12px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom:20px;
  }
`;
export const GameImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;  
  img{
    max-width: 400px;
    width:100%;
    margin: 0px 0px 10px 0px;
    // @media (max-width: 360px) {
    //   width:100%;
    //   height: 200px !important;
    // }
}
`;
export const AddedFire = styled.div`
position: absolute;
left: 50%;
top: 58%;
transform: translate(-50%,-50%);
  width:150px;
  height:50px;
  animation: pulse .7s linear;
  @keyframes pulse {
    0% {opacity:0;}
  100% {opacity:1;}
  }
  svg{
    postion:relative;
    width:100%;
    height:100%;
  }
    .text_added{
      font-size:12px;
    }
`;
export const Nav = styled.div`
    display: flex;
    padding-top: 15px;
	padding-bottom: 15px;
    justify-content: flex-end;
`;

export const NavButtons = styled.button`
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 1.8em;
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;
  font-size: 25px;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
`;
export const ExitButtons = styled.button`
display:block;
  border-radius:  20px;
  background:${({ theme }) => theme.colors.primary};
  text-align: center;
  color:#fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  padding: 8px 25px;
  margin:0 auto;
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
    @media (max-width: 768px) {
      width:100%;
    }
`;
export const ArrowRight = styled.span`
  :before {
    font-family: efl_iconfont;
    content: '\\52'
  }
`;



export const TextDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;  
    img{
        width: 400px;
        min-width: 400px;
        margin:0px auto; 
        @media (max-width: 400px) and (min-width: 300px) {
          width: 300px;
          min-width: 300px;
        }
        @media (max-width: 300px) {
          width: 260px;
          min-width: 260px;
        }
    }

    .superMain {
      position: relative;
    }
    .subImdTxt {
      position: absolute;
      top: 10px;
      font-size: 9px;
      left: 0px;
      right: 0px;
      width: 400px;
      text-align:left;

      @media (max-width: 400px) and (min-width: 300px) {
        width: 300px;
        min-width: 300px;
        top: 4px
      }
      @media (max-width: 300px) {
        width: 260px;
        min-width: 260px;
        top: 4px
      }

      p{
        display:flex;
        span{
          white-space: nowrap;
        } 
        .apOne {
          margin-left: 9%;
          min-width: 18%;
          text-align: left;
        }
        .apTwo {
            margin-left: 7% !important;
            text-align: center;
            min-width: 25%;
        }
        .apThree {
            margin-right: 4% !important;
            margin-left: 11% !important;
            text-align: center;
            min-width: 10%;
        }
        .apFour {
            margin-left: 0;
        }
        @media (max-width: 400px) and (min-width: 300px) {
          .apOne {
            margin-left: 8%;
          }
          .apTwo {
            margin-left: 7% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 11% !important;
          }
        }
        @media (max-width: 300px) {
          .apOne {
            margin-left: 9%;
          }
          .apTwo {
            margin-left: 7% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 9% !important;
          }
        }
      }

      p.th{
        .apThree {
            margin-right: 2% !important;
            margin-left: 13% !important;
        }
        @media (max-width: 400px) and (min-width: 300px) {
          .apOne {
            margin-left: 10%;
          }
          .apTwo {
            margin-left: 7% !important;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 13% !important;
          }
        }
        @media (max-width: 300px) {
          .apThree {
              margin-right: 2% !important;
              margin-left: 13% !important;
          }
        }
      }
      p.fr{
        .apOne {
          margin-left: 11%;
        }
        .apTwo {
          margin-left: 6% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 12% !important;
        }
        @media (max-width: 400px) and (min-width: 300px) {
          .apOne {
            margin-left: 10%;
          }
          .apTwo {
            margin-left: 7% !important;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 13% !important;
          }
        }
        @media (max-width: 300px) {
          .apOne {
            margin-left: 9%;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 13% !important;
          }
        }
      }
      p.el{
        .apOne {
          margin-left: 11%;
        }
        .apTwo {
          margin-left: 6% !important;
        }
        .apThree {
            margin-right: 2% !important;
            margin-left: 8% !important;
        }
        @media (max-width: 400px) and (min-width: 300px) {
          .apOne {
            margin-left: 10%;
          }
          .apTwo {
            margin-left: 7% !important;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 2% !important;
          }
        }
        @media (max-width: 300px) {
          .apOne {
            margin-left: 9%;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 13% !important;
          }
        }
      }
      p.ny{
        .apOne {
          margin-left: 6%;
        }
        .apTwo {
          margin-left: 10% !important;
        }
        .apThree {
            margin-right: 3% !important;
            margin-left: 11% !important;
        }
        @media (max-width: 400px) and (min-width: 300px) {
          .apOne {
            margin-left: 6%;
          }
          .apTwo {
            margin-left: 8% !important;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 10% !important;
          }
        }
        @media (max-width: 300px) {
          .apOne {
            margin-left: 4%;
          }
          .apTwo {
            margin-left: 6% !important;
          }
          .apThree {
              margin-right: 2% !important;
              margin-left: 8% !important;
          }
        }
      }

      p.lg{
        @media (max-width: 300px) {
          .apOne {
            margin-left: 7%;
          }
        }
      }

      p.lg{
        @media (max-width: 300px) {
          .apOne {
            margin-left: 9%;
          }
          .apTwo {
            margin-left: 9% !important;
          }
          .apThree {
              margin-right: 3% !important;
              margin-left: 11% !important;
          }
        }
      }
    }
  }
   
`;