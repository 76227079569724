import React, { useReducer, } from 'react'
import { Provider } from 'react-redux'
import './App.css'
import { loadImage } from './helpers/image_loader'
import { getActions, GetInitialState, reducer } from './helpers/reducerHelper'
import store from './redux/store';
import RoutingPlayer from './routing_player';
import Body from './body'
const { images } = require('./helpers/assets.json')

export const DataContext = React.createContext({})

function AppContent() {

  const [state, dispatch] = useReducer(reducer, GetInitialState())
  const actions = getActions(dispatch)

  return (
    <DataContext.Provider value={[state, actions]}>
    <Body>        
      <RoutingPlayer />
      <div className='d-none'>
        {images.map((im, i) => (
          <img key={i} src={loadImage(im.split('.')[0])} alt='begini' />
        ))}
      </div>
    </Body>
  </DataContext.Provider>
  )
}
function App() {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
}


export default App
