
import React, { useState } from 'react';
import Player from "../Player/Player";
import fin_des from '../Player/stories/fin_des';
import flashcards from '../Player/stories/flashcards';
import financial_decisions from '../Player/stories/financial_decisions';
import financial_decisions_euro from '../Player/stories/financial_decisions_euro';
import bus_info from '../Player/stories/business_info';
import finding_patterns from '../Player/stories/finding_patterns';
import fire_building from "../Player/stories/fire_building";
import con_decision from "../Player/stories/con_decision";
import coins_personal from "../Player/stories/coins_personal";
import coin_business from "../Player/stories/coins_business";
import self_image from "../Player/stories/self_image";
import demographic_info from "../Player/stories/demographic_info";
import trans_start from "../Player/stories/trans_start";
import trans_start_custom from "../Player/stories/trans_start_custom";
import trans_end from "../Player/stories/trans_end";
import sand_truck from "../Player/stories/sand_truck";

import {  useSelector } from 'react-redux';
import trans_start_welcome from '../Player/stories/trans_start_welcome';

const uncdf = [
  { props: fin_des, name: 'Financial Decision' },
  { props: flashcards, name: 'Flash Card' },
  { props: financial_decisions, name: 'Financial Decisions' },
  { props: financial_decisions_euro, name: 'Financial Decisions Euro' },
  { props: bus_info, name: 'Bus info' },
  { props: finding_patterns, name: 'Finding Patterns' },
  { props: fire_building, name: 'Fire Building' },
  { props: con_decision, name: "Consumption Decisions" },
  { props: sand_truck, name: 'Sand Truk' },
  { props: coins_personal, name: 'Coin Personal' },
  { props: coin_business, name: 'Coin Business' },
  { props: self_image, name: 'Self Image' },
  { props: demographic_info, name: 'Demo Graphic Info' },
  { props: trans_start, name: 'Welcome!' },
  { props: trans_start_custom, name: 'Welcome!' },
  { props: trans_start_welcome, name: 'Welcome!' },
  { props: trans_end, name: 'End' }
];

const CommonContainer = ({ index }) => {
  const [modules] = useState(uncdf[index]);

  const { client } = useSelector(State => State.config);

  const onModuleFinish = (_observations, moduleName, status) => {
  }

  return (
    <div className="App">

      <Player
        key={`${modules.name}`}
        module={{ locale: client.language, ...modules.props }}
        onModuleFinish={onModuleFinish}
      />
    </div>
  );
}

export default CommonContainer;
