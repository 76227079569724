import React, { useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Buttons,
  Container,
  Group,
  Header,
  ModalContainer,
  OptionList,
} from "./LanguageButtonStyles";
import RadioButton from "../../components/RadioButton/RadioButton";
import { ThemeProvider } from "styled-components";
import useThemeConfig from "../../hooks/useThemeConfig";
import setTheme from "../../context/ThemeProvider";
import { isRtl } from "../../lib/util";
import { setClient } from "../../redux/config/actionCreator";
import { COOKIE } from "../../helpers/constant";
import CookieHelper from "../../helpers/cookieHelper";
import { DataService } from "../../config/dataService/dataService";
import { useEffect } from "react";
import useLanguage from "../../hooks/useLanguage";

const LanguageButton = () => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.config);
  const { messages,setLocale } = useLanguage()
  const themeConfig = useThemeConfig();
  const user = CookieHelper.get(COOKIE.USER)
  
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(client?.userLanguageCode || client?.languageCode );
  const [modalState, setModalState] = useState(false);
  const [displayLanguageName, setDisplayLanguageName] = useState(() => {
    const userLangCode = client?.userLanguageCode || client?.languageCode;
    
    const selectedLanguage = client?.languageOptions.find(
      (lang) => lang.code === userLangCode || 
      (userLangCode.startsWith('en') && lang.code.startsWith('en'))
    );
  
    return selectedLanguage?.name || "Select Language";
  });
 

  const languageOptions = client?.languageOptions || [];

  useEffect(() => {
    dispatch(setClient({...client, languageCode: selectedLanguageCode, isRtl: isRtl(selectedLanguageCode), languageOptions,userLanguageCode:selectedLanguageCode}));
    setLocale(selectedLanguageCode);
  }, [])

  const onUpdateLanguage = async (langCode) => {
    try {
      await DataService.put("psychometrics/language", {
        integrationId: user?.integration_id,
        uid: user?.uid,
        userLanguageCode:langCode,
        companyId:user?.company_id
      })
    }
    catch (err) {
     console.log(err)
      }
    }
  // Memoized selected language name for display after confirmation
  const selectedLanguageName = useMemo(() => {
    return languageOptions.find((lang) => lang.code === selectedLanguageCode)?.name || "Select Language";
  }, [selectedLanguageCode, languageOptions]);

  const handleConfirmSelection = useCallback(async() => {   
    dispatch(setClient({...client, languageCode: selectedLanguageCode, isRtl: isRtl(selectedLanguageCode), languageOptions,userLanguageCode:selectedLanguageCode }));
    await onUpdateLanguage(selectedLanguageCode)
    // Update the display name only on confirmation
    setLocale(selectedLanguageCode);
    setDisplayLanguageName(selectedLanguageName);
    setModalState(false);
    
  }, [dispatch, selectedLanguageCode, selectedLanguageName, languageOptions]);

  return (
    <ThemeProvider theme={setTheme(themeConfig)}>
      <ModalContainer size="lg" centered isOpen={modalState} onClosed={() => setModalState(false)}>
        <Header toggle={() => setModalState(false)}>
          {messages?.langSwitcherTitle || "Select Survey Language"}
        </Header>
        <Group>
          <OptionList>
            {languageOptions.map(({ code, name }) => (
              <RadioButton
                key={code}
                fontSize="16px"
                identifier={code}
                onClick={() => setSelectedLanguageCode(code)}
                selected={selectedLanguageCode === code}
                label={name}
                className="directionRtl"
              />
            ))}
          </OptionList>
        </Group>
        <div style={{ justifyContent: "center" }} className="row">
          <Buttons onClick={handleConfirmSelection} className="rtl-buttons">
          {messages?.langSwitcherButtonName || "Continue"}
          </Buttons>
        </div>
      </ModalContainer>
      <Container>
        <Button onClick={() => setModalState(true)}>
          {displayLanguageName.toUpperCase()}
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export default LanguageButton;
