/* eslint-disable react-hooks/exhaustive-deps */

import { CenterContent, GlobalBaseStyle } from '../../assets/styles/BaseStyle'
import { ContentSub, Group, NextButton, PopModalBody, PopUp, SubContent, Title } from './ConfidentialityCheckStyles'
import React, { useContext, useEffect, useState, useRef } from 'react'

import { LocaleContext } from '../../context/LocalContext'
import PropTypes from 'prop-types'
import RadioButton from '../../components/RadioButton/RadioButton'
import _get from 'lodash.get'
import {  getText } from '../../helpers'
import { useDynamicContent } from '../../hooks'
import { getClassName } from '../../lib/util'

const ConfidentialityCheck = ({
  module,
  observation,
  options,
  center,
  handPointer,
  value,
  defaultValue,
  onChangeHandler,
  hide_hands,
  handTarget,
  clearing_options,
  type,
  privacyUrl,
  option1,
  handleNavigation,
  add_to_state = true,
  clientName,
  meta,
 ...rest
}) => {

  const translation = useContext(LocaleContext)
  const [selected, setSelected] = useState(_get(value, 'value', null))
  const [sequence, setSequence] = useState(_get(value, 'sequence', []))
  const [showPopup, setShowPopup] = useState(false)
  const [label, setLabel] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const componentRef = useRef(true);
  const contents = useDynamicContent(module, observation)
  useEffect(() => {
    setSelected(_get(value, 'value', value || null));
    setLabel(_get(value, 'label', null));
    setSequence(_get(value, 'sequence', []));

  }, [observation, value])

  useEffect(() => {
    if (!componentRef.current) {
      if (typeof onChangeHandler === 'function') {
        onChangeHandler({
          value: selected ? 1 : 0,
          other_option_value: null,
          sequence,
          label
        }, {})
      }
    } else {
      componentRef.current = false
    }
  }, [selected]);

  const handleSelect = (vl, lbl) => {
    setSelected(vl)
    setLabel(lbl)
    setSequence(sq => {
      if (vl !== sq[sq.length - 1]) sq.push(vl);
      return sq
    });
    // show popup
    if (vl === 0) {
      setShowPopup(true)
    }
  };
  const generateOptions = () => {
    return options.map((item, index) => {
      const is_selected = selected === item.value;
      return (
        <RadioButton
          key={item.value}
          identifier={index}
          onClick={() => handleSelect(item.value, item.label || langText(`option${index + 1}`))}
          pointer={handPointer && is_selected}
          selected={is_selected}
          label={langText(`option${index + 1}`)}
          className={"directionRtl"}
        />
      )
    })
  }
  const onOkayClick = () => {
    setDisabled(true)
    handleNavigation(1, true.valueOf, true)
  }

  const langText = (key)=>{
    return getText({text: rest[key] , contents, translation, observation , key})
  }

  return (
    <>
      <GlobalBaseStyle />
      <PopUp isOpen={showPopup}>
        <PopModalBody>
          <div className={`title`}>{langText('popup.title')}</div>
          <ContentSub>{langText(`popup.sub_title`)}</ContentSub>
          <NextButton
            disabled={disabled}
            onClick={onOkayClick}
            label={langText(`popup.yes_button`)}
          >
          </NextButton>
          <NextButton
            disabled={disabled}
            onClick={setShowPopup}
            label={langText(`popup.no_button`)}
          >
          </NextButton>
        </PopModalBody>
      </PopUp>
      <CenterContent center={center}>
        <div>        
          {!handPointer && (
            <>
              <Title 
              className={getClassName(meta?.title?.alignment)}
              alignment={meta?.title?.alignment} 
              > 
                {langText('title')} 
              </Title>              
            <SubContent
            className='rtl-text'
            > 
                {` ${langText('subtitle')} ${clientName} ${langText('subtitle_2')} `}
            </SubContent>
              <SubContent
              className='rtl-text'
              >
                <a href={privacyUrl} target='_blank' rel="noreferrer" >{` ${langText('linkText')} `}</a>
              </SubContent>
              <SubContent className='rtl-text'>
                {langText('termText')} </SubContent>
            </>
          )}
        </div>
        <Group>
          {generateOptions()}
        </Group>
        {handPointer && (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </CenterContent>
    </>
  )
}

ConfidentialityCheck.defaultProps = {
  options: [],
  defaultValue: [],
  handPointer: false,
  title: '',
  sub_title: '',
  warning: '',
  instruction: '',
}

ConfidentialityCheck.propTypes = {
  value: PropTypes.object,
  handPointer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func,
  title:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  clientName: PropTypes.string,
  module: PropTypes.string,
  meta: PropTypes.object

}

export default ConfidentialityCheck
