import styled from 'styled-components';

export const LeftPane = styled.div`
  	text-align: right;
	img {
		width: 360px;
		max-width: 100%;
		margin-top:-47px;
	}
	
	@media (max-width: 768px) {
	  text-align: center;
		margin: 0;

		img {
			width: 100%;
			margin: 20px auto;
		}
	}
`;

export const RightPane = styled.div`
  	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin:10px auto;
	img {
		max-width: 120px;
		margin-right: 15px;
	}
	
	@media (max-width: 768px) {
	  text-align: left;
		/* flex-direction: row-reverse; */
		justify-content: center;
	}
`;

export const TextStyle = styled.div`
  @media (max-width: 768px) {
	  text-align: center;
	}
`;

export const Button = styled.button`

margin:30px 0;
background: ${({ theme }) => theme.colors.primary};
font-size:18px;
text-align: center !important;
width: 30%;
border-radius: 50px;
padding: 10px 0px;
color:#fff;
  border: none;
  outline: none;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.3), 0 6px 12px rgba(0, 0, 0, 0.4);
  &:active,
    &.active {
    	box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2), 0 3px 12px rgba(0, 0, 0, 0.3);
        border: 0;
    }
    &:focus {
        outline: none;
    }
@media (min-width:320px) and  (max-width:767px){
	width:100%;
	margin:10px 15px
}
	
`
